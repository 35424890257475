








import { Component, Vue } from 'vue-property-decorator';
import lang from '@/lang';
import { FrontE } from '@/sdk/common/SysConst';
import storage from '@/sdk/common/Storage';
@Component
export default class InsLayer extends Vue {
  public show:boolean = false;
  created () {
  }
  get pcBuilding () {
    return FrontE.pcBuilding;
  }
  mounted () {
    if (this.$refs.layerbody) {
      this.$loading({
        target: this.$refs.layerbody as any,
        text: '',
        fullscreen: true,
        spinner: 'el-loading-spinner2',
        customClass: 'IconSize'
      });
    }
  }
  updated () {
    if (this.$refs.layerbody) {
      this.$loading({
        target: this.$refs.layerbody as any,
        text: '',
        fullscreen: true,
        spinner: 'el-loading-spinner2',
        customClass: 'IconSize'
      });
    }
  }
  public hidden () {
    this.show = false;
    document.body.style.overflowY = 'auto';
  }
  public showL () {
    this.show = true;
    setTimeout(() => { window.scrollTo(0, 0); document.body.style.overflowY = 'hidden'; }, 50);
  }
}
